import * as React from 'react'
import { graphql } from 'gatsby'

import { cn, getLocaleContentFromNodes } from '../utils'
import Layout from '../components/Layout'
import Seo from '../components/Seo'

import {
  searchClient,
  getLocaleIndexName,
  SearchSection,
} from '../components/search'
import UnstyledHeading from '../components/shared/UnstyledHeading'
import UnstyledRichtext from '../components/shared/UnstyledRichtext'

const SearchPage = ({ pageContext, location, data }) => {
  const siteConfig = getLocaleContentFromNodes({
    nodes: data?.siteConfigurations?.nodes,
    language: pageContext.language,
  })
  const context = {
    ...pageContext,
    siteConfig,
  }
  const searchIndex = { name: getLocaleIndexName(pageContext.language) }

  return (
    <Layout
      context={context}
      location={location}
      floatingSiteNavigation={false}
    >
      <div className="box bg-white py-20 md:pt-30">
        <div className="mx-auto lg:w-2/3">
          <UnstyledHeading
            order={1}
            className={cn(
              'font-heading font-bold uppercase whitespace-pre-line',
              'text-32 md:text-40 lg:text-48 xl:text-56 2xl:text-72',
            )}
          >
            {siteConfig.searchPageTitle}
          </UnstyledHeading>
          <div className="mt-6">
            {siteConfig.searchOff ? (
              <UnstyledRichtext
                className="text-16 md:text-18 lg:text-20 xl:text-26 2xl:text-32"
                document={siteConfig.searchOffMessage}
              />
            ) : (
              <SearchSection
                indices={[searchIndex]}
                searchClient={searchClient}
                popularSearches={siteConfig.popularSearches}
                popularSearchesTitle={siteConfig.popularSearchesTitle}
              />
            )}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default SearchPage

export const Head = ({ pageContext, location, data }) => {
  const siteConfig = getLocaleContentFromNodes({
    nodes: data.siteConfigurations?.nodes,
    language: pageContext.language,
  })

  const seo = {
    title: siteConfig?.searchPageTitle || 'Search',
  }

  return <Seo location={location} seo={seo} />
}

export const query = graphql`
  query ($language: String! = "en") {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    siteConfigurations: allStoryblokEntry(
      filter: {
        field_component: { eq: "SiteConfiguration" }
        lang: { in: ["default", $language] }
      }
    ) {
      nodes {
        lang
        content
      }
    }
  }
`
